
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class HeaderComponent extends Vue {
  activeIndex: string = "1";
  menuIsActive: boolean = false;
  windowWidth: number = 0;
  windowWidthFlag: boolean = true;
  lang: string = localStorage.getItem("preferredLanguage") || "ua"; // Завантажуємо збережену мову або встановлюємо "ua" як стандартну

  menuNav() {
    this.menuIsActive = !this.menuIsActive;
  }

  // Метод для зміни мови та збереження її в localStorage
  switchLang() {
    this.$i18n.locale = this.lang; // Оновлюємо мову додатка
    localStorage.setItem("preferredLanguage", this.lang); // Зберігаємо вибір мови
  }

  getWindowWidth() {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowWidthFlag = this.windowWidth > 960;
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);
      this.getWindowWidth(); // Ініціалізуємо ширину вікна
    });
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
}
