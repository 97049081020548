
import { Vue, Component } from "vue-property-decorator"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

library.add(faFacebook, faInstagram)

@Component({})
export default class HeaderComponent extends Vue {
	year: number = new Date().getFullYear()
}
