import Vue from "vue"
import "./plugins/fontawesome"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import i18n from "./i18n"
import "./plugins/element.js"
import "normalize.css"

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app")
