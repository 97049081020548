
import { Vue, Component } from "vue-property-decorator"
import ProductList from "../components/ProductList.vue"
import ShoppingCart from "../components/ShoppingCart.vue"

@Component({
	components: {
		ProductList,
		ShoppingCart,
	},
})
export default class Shop extends Vue {}
